const projects = [
  ["projects"],
  {
    name: "Wynters Dreams",
    path: "https://www.wyntersdreams.com/",
    text:
      "Wynter's Dreams is a digital bookstore that uses storytelling to spark curiosity and foster a love of learning. Offering curated, educational digital books, it creates a safe space where every story is an adventure.",
    github: "https://github.com/chirag-08x/Wynters-Dreams",
    image: "./images/main-project-img/prjct-4.png",
  },
  {
    name: "Evivve",
    path: "https://app.evivve.com/evivve3/",
    text:
      "Evivve is a multiplayer online game-based learning tool that revolutionizes corporate learning by blending immersive gameplay with experiential learning.",
    github: "https://github.com/chirag-08x/Evivve3",
    image: "./images/main-project-img/prjct-3.png",
  },
  {
    name: "Micro",
    path: "https://www.getmicro.co/",
    text:
      "Micro was created in 2017 to simplify real estate investments through fractional ownership. We aim to make this vast market accessible and transparent for everyone.",
    github: "#",
    image: "./images/main-project-img/prjct-5.png",
  },
  {
    name: "sorting visualizer",
    path: "https://sorting-visualizer08x.netlify.app/",
    text:
      "Welcome to Sorting Visualizer. This app is build to visualize famous sorting algorithm like Merge Sort, Quick Sort, Heap Sort and Bubble Sort.",
    github: "https://github.com/chirag-08x/Sorting-Visualizer",
    image: "./images/main-project-img/prjct-1.png",
  },

  ["react projects"],

  {
    name: "birthday reminder",
    path: "https://birthday-reminder-08x.netlify.app/",
    text:
      "A simple birthday reminder app which lets you know when your birthday is.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-1.png",
  },
  {
    name: "tours",
    path: "https://tours-08x.netlify.app/",
    text:
      "A simple tours app which lets you explore countries around the world.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-2.png",
  },
  {
    name: "reviews",
    path: "https://reviews-08x.netlify.app/",
    text: "A simple review app which lets you read people reviews.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-3.png",
  },
  {
    name: "accordion(FAQ)",
    path: "https://accordion-08x.netlify.app/",
    text: "A simple accordion where you can see frequently asked questions.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-4.png",
  },
  {
    name: "menu",
    path: "https://menu-08x.netlify.app/",
    text: "A simple restaurant menu where you can filter out dishes.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-5.png",
  },
  {
    name: "tabs",
    path: "https://tabs-08x.netlify.app/",
    text: "A simple apps that lets you switch between Tabs, built using React.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-6.png",
  },
  {
    name: "lorem ipsum",
    path: "https://lorem-generator-08x.netlify.app/",
    text:
      "A simple Lorem Ipsum Generator App where you can choose the number of lorem ipsum paragraphs you want and the app will generate it, built using React.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-8.png",
  },
  {
    name: "color generator",
    path: "https://color-generator-08x.netlify.app/",
    text:
      "A simple color generator app which generates 20 shades of any given color value.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-9.png",
  },
  {
    name: "grocery bud",
    path: "https://grocery-bud-08x.netlify.app/",
    text:
      "A simple Grocery List App where you can add, remove, edit, items in the grocery list.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-10.png",
  },
  {
    name: "navbar",
    path: "https://navbar-08x.netlify.app/",
    text: "A simple Navbar built using React.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-11.png",
  },
  {
    name: "sidebar and modal",
    path: "https://sidebar-modal-08x.netlify.app/",
    text: "A simple Sidebar and Modal App, built using React.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-12.png",
  },
  {
    name: "cart",
    path: "https://cart-08x.netlify.app/",
    text: "A simple cart in which you can add, remove items.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-14.png",
  },
  {
    name: "cocktails",
    path: "https://cocktail-08x.netlify.app/",
    text:
      "A simple cocktail app where you can search your favorite cocktail, and can find details about it.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-15.png",
  },
  {
    name: "markdown preview",
    path: "https://markdown-08x.netlify.app/",
    text:
      "A simple Markdown app for creating formatted text using a plain-text editor.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-16.png",
  },
  {
    name: "random person",
    path: "https://random-person-08x.netlify.app/",
    text:
      "A simple App where you can fetch random users from random person API.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-17.png",
  },
  {
    name: "pagination",
    path: "https://pagination-08x.netlify.app/",
    text: "A simple App where you can paginate between pages.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-18.png",
  },
  {
    name: "infinite scroll",
    path: "https://infinite-scroll-08x.netlify.app/",
    text:
      "A simple app where you can scroll through photos infinitely they will never end.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-19.png",
  },
  {
    name: "dark mode",
    path: "https://dark-mode-08x.netlify.app/",
    text: "A simple App where you can switch between dark mode and light mode.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-20.png",
  },
  {
    name: "movies db",
    path: "https://moviesdb-08x.netlify.app/",
    text:
      "A simple movies App where you can search your favorite movies and can find other details about them, built using MoviesDB API with React.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-21.png",
  },
  {
    name: "hacker news",
    path: "https://news-08x.netlify.app/",
    text:
      "A simple News App where you can read latest news and can even search topics that you want to explore, built using React.",
    github:
      "https://github.com/chirag-08x/React/tree/main/03-Basic-Projects/basic-projects",
    image: "./images/react-project-img/prjct-22.png",
  },

  ["javascript projects"],
  {
    name: "color flipper",
    path: "https://colour-flipper08x.netlify.app/",
    text:
      "A simple color flipper app that will generate new color on every click.",
    github: "https://github.com/chirag-08x/Color-Filpper",
    image: "./images/js-project-img/prjct-1.png",
  },
  {
    name: "counter",
    path: "https://counter08x.netlify.app/",
    text:
      "A simple counter app where you can increase or decrease current number by 1 and can also set it back to default 0.",
    github: "https://github.com/chirag-08x/Counter",
    image: "./images/js-project-img/prjct-2.png",
  },
  {
    name: "reviews/quotes",
    path: "https://reviews08x.netlify.app/",
    text: "A simple app that reviews people.",
    github: "https://github.com/chirag-08x/Reviews",
    image: "./images/js-project-img/prjct-3.png",
  },
  {
    name: "navbar",
    path: "https://navbar08x.netlify.app/",
    text: "A simple responsive fully functional Navbar.",
    github: "https://github.com/chirag-08x/Navbar",
    image: "./images/js-project-img/prjct-4.png",
  },
  {
    name: "sidebar",
    path: "https://sidebar08x.netlify.app/",
    text: "A simple responsive fully functional Sidebar.",
    github: "https://github.com/chirag-08x/Sidebar",
    image: "./images/js-project-img/prjct-5.png",
  },
  {
    name: "modal",
    path: "https://modal08x.netlify.app/",
    text: "A simple responsive fully functional Sidebar.",
    github: "https://github.com/chirag-08x/Modal",
    image: "./images/js-project-img/prjct-6.png",
  },
  {
    name: "QnA",
    path: "https://qna08x.netlify.app/",
    text: "Question and Answer App.",
    github: "https://github.com/chirag-08x/QnA",
    image: "./images/js-project-img/prjct-7.png",
  },
  {
    name: "menu",
    path: "https://menu08x.netlify.app/",
    text:
      "A simple food and drinks menu app where you can choose variety of foods and drinks of your choice.",
    github: "https://github.com/chirag-08x/Menu",
    image: "./images/js-project-img/prjct-8.png",
  },
  {
    name: "video",
    path: "https://video08x.netlify.app/",
    text: "A simple video app where you can play and pause a video.",
    github: "https://github.com/chirag-08x/Video",
    image: "./images/js-project-img/prjct-9.png",
  },
  {
    name: "tabs",
    path: "https://tabs08x.netlify.app/",
    text: "A simple page that has multiple tabs where you can switch.",
    github: "https://github.com/chirag-08x/Tabs",
    image: "./images/js-project-img/prjct-10.png",
  },
  {
    name: "smooth scroll",
    path: "https://smooth-scroll08x.netlify.app/",
    text:
      "A simple smooth scroll app where you can smoothly scroll through a page.",
    github: "https://github.com/chirag-08x/Smooth-Scroll",
    image: "./images/js-project-img/prjct-11.png",
  },
  {
    name: "countdown timer",
    path: "https://countdown08x.netlify.app/",
    text: "A countdown timer where you can set the time backwards.",
    github: "https://github.com/chirag-08x/Countdown",
    image: "./images/js-project-img/prjct-12.png",
  },
  {
    name: "lorem-ipsum generator",
    path: "https://lorem-generator08x.netlify.app/",
    text: "A simple app where you can generate lorem ipsum paragraphs.",
    github: "https://github.com/chirag-08x/Lorem-Ipsum-Generator",
    image: "./images/js-project-img/prjct-13.png",
  },
  {
    name: "grocery bud",
    path: "https://todo08x.netlify.app/",
    text:
      "A simple cart where you can add, edit or remove items of your choice.",
    github: "https://github.com/chirag-08x/Grocery-Bud",
    image: "./images/js-project-img/prjct-14.png",
  },
  {
    name: "slider",
    path: "https://todo08x.netlify.app/",
    text: "A simple slider which let's you switch between photos smoothly.",
    github: "https://github.com/chirag-08x/js-Slider",
    image: "./images/js-project-img/prjct-15.png",
  },
  {
    name: "random jokes",
    path: "https://chuck-random-joke.netlify.app/",
    text: "Random Chuck Norris Jokes fetched using Random Jokes API.",
    github: "https://github.com/chirag-08x/Random-Joke",
    image: "./images/js-project-img/prjct-16.png",
  },
  {
    name: "random user",
    path: "https://random-usr.netlify.app/",
    text: "Random user fetching using Random user API.",
    github: "https://github.com/chirag-08x/Random-User",
    image: "./images/js-project-img/prjct-17.png",
  },
  {
    name: "cocktails API",
    path: "https://cocktail-ajax.netlify.app/",
    text:
      "Simple Cocktail App where you can search and find details about your favorite cocktails.",
    github: "https://github.com/chirag-08x/Cocktails",
    image: "./images/js-project-img/prjct-18.png",
  },
  ["HTML/CSS Projects"],
  {
    name: "tea station",
    path: "https://your-tea-station.netlify.app/",
    text: "A simple tea site build using HTML/CSS only.",
    github: "https://github.com/chirag-08x/Tea-Station-Site",
    image: "./images/html-css-project-img/prjct-1.png",
  },
  {
    name: "backboards tours and travels",
    path: "https://backroad-travels.netlify.app/",
    text: "A simple tour and travels site built using HTML/CSS only.",
    github: "https://github.com/chirag-08x/BackBoards-Tour-Company",
    image: "./images/html-css-project-img/prjct-2.png",
  },
];

export default projects;
